import './Input.scss';

import {
  Barcode,
  EnvelopeSimple,
  Eye,
  Lock,
  UserCircle,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import { ChangeEventHandler, FocusEventHandler, useState } from 'react';

import Icon, { IconName } from '../../icons/Icon';

const icons = {
  user: <UserCircle size={24} weight="fill" />,
  lock: <Lock size={24} weight="fill" />,
  barcode: <Barcode size={24} weight="bold" />,
  envelope: <EnvelopeSimple size={24} weight="bold" />,
};

interface Props {
  label?: string;
  error?: boolean;
  name: string;
  id?: string;
  'data-testid'?: string;
  type?: string;
  value: string;
  autoComplete?: string;
  required?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  addEyeIcon?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  icon?: keyof typeof icons;
  suffixIcon?: IconName;
  disabled?: boolean;
  isFullWidth?: boolean;
  variant?: 'default' | 'no-underline' | 'no-border';
}

const Input = ({
  label = '',
  onChange,
  error = false,
  name = '',
  id,
  'data-testid': dataTestId,
  value = '',
  type = 'text',
  onBlur,
  autoComplete = '',
  required = false,
  placeholder = '',
  readOnly = false,
  addEyeIcon = false,
  icon,
  suffixIcon,
  disabled = false,
  isFullWidth = false,
  variant = 'default',
}: Props) => {
  const [inputType, setInputType] = useState(type);
  const className = 'c-Input';

  const activeClassNames = classNames(className, {
    [`${className}--error`]: error,
    [`${className}--w-eye-icon`]: addEyeIcon,
    [`${className}--disabled`]: disabled,
    [`${className}--fullWidth`]: isFullWidth,
    [`${className}--no-underline`]: variant === 'no-underline',
    [`${className}--new-input`]: variant !== 'default',
  });

  const inputClassNames = classNames({
    [`${className}__input`]: variant !== 'no-underline',
    [`${className}__input--new-input`]: variant !== 'default',
  });

  const handleEyeClick = () => {
    const updatedType = inputType === 'password' ? 'text' : 'password';
    setInputType(updatedType);
  };

  const renderEyeIcon = () => {
    return addEyeIcon ? (
      <span
        className={`${className}__eye-icon-container`}
        onMouseDown={(event) => event.preventDefault()}
        onClick={handleEyeClick}
      >
        <Eye size={28} weight="bold" />
      </span>
    ) : null;
  };

  const renderSuffixIcon = () => {
    return suffixIcon ? <Icon name={suffixIcon} /> : null;
  };

  return (
    <label className={activeClassNames}>
      {label}
      <div className={`${className}--inputContainer`}>
        {icon && icons[icon]}
        <input
          className={inputClassNames}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          id={id}
          data-testid={dataTestId}
          value={value}
          type={inputType}
          autoComplete={autoComplete}
          required={required}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
        />
        {renderSuffixIcon()}
      </div>
      {renderEyeIcon()}
    </label>
  );
};

export default Input;
