import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getUserValidator = (t: TFunction) => ({
  email: yup
    .string()
    .trim()
    .lowercase()
    .max(
      128,
      t('form.error.maxLength', "Can't be longer than {{maxLength}}", {
        maxLength: 128,
      }),
    )
    .email(
      t('login.invalid_email_error', 'Please enter a valid email address.'),
    )
    .required(t('login.email_required_error', 'An email address is required.')),
  password: yup
    .string()
    .required(t('login.password_required_error', 'A password is required.'))
    .min(
      8,
      t(
        'form.error.minLength',
        "Your password can't be shorter than {{minLength}}",
        { minLength: 8 },
      ),
    )
    .max(
      256,
      t(
        'form.error.passwordMaxLength',
        "Your password can't be longer than {{maxLength}}",
        { maxLength: 256 },
      ),
    )
    .matches(
      /[a-z]/,
      t(
        'form.error.lowercase',
        'Must contain at least one lowercase character',
      ),
    )
    .matches(
      /[A-Z]/,
      t(
        'form.error.uppercase',
        'Your password must contain at least one uppercase character',
      ),
    )
    .matches(
      /[0-9]/,
      t('form.error.digit', 'Your password must contain at least one digit'),
    ),
  confirmPassword: yup
    .string()
    .required(
      t(
        'form.password_confirm_required_error',
        'Password confirmation is required.',
      ),
    )
    .oneOf(
      [yup.ref('password'), ''],
      t('form.password_mismatch_error', 'Your passwords must match.'),
    ),
  privacy_accepted: yup
    .boolean()
    .oneOf(
      [true],
      t(
        'form.accept_privacy_policy_error',
        'You must accept the Privacy and Cookie Policy to continue',
      ),
    )
    .required(
      t(
        'form.accept_privacy_policy_error',
        'You must accept the Privacy and Cookie Policy to continue',
      ),
    ),
});
